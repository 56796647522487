import React from 'react';

function Footer() {
    return (
        <React.Fragment>
            <footer className="bg-white rounded shadow p-1 mb-1 mt-1">
                <div className="row">
                    <div className="col-12 col-md-4 col-xl-6 mb-4 mb-md-0">
                 
                    </div>
                    <div className="col-12 col-md-8 col-xl-6 text-center text-lg-start">

                        <ul className="list-inline list-group-flush list-group-borderless text-md-end mb-0">
                            <li className="list-inline-item px-0 px-sm-2">
                                <a href="https://www.site.com"></a>
                            </li>
                            <li className="list-inline-item px-0 px-sm-2">
                                <a href="https://www.site.com"></a>
                            </li>
                            <li className="list-inline-item px-0 px-sm-2">
                                <a href="https://www.site.com"></a>
                            </li>
                            <li className="list-inline-item px-0 px-sm-2">
                                <a href="https://t.me/Wiuxor"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;
